import React from "react"
import CountUp from "react-countup"
import VisibilitySensor from "react-visibility-sensor"

const CountValueComponent = ({ number }) => {
  return (
    <CountUp end={number} decimals={number % 1 === 0 ? 0 : 1}>
      {({ countUpRef, start }) => (
        <VisibilitySensor onChange={start} delayedCall>
          <div className="counter" ref={countUpRef} />
        </VisibilitySensor>
      )}
    </CountUp>
  )
}

const FactItem = (item, key) => {
  return (
    <li key={`card-${key}`} className="data-item-wrapper">
      <div className="data-item">
        <div className="prefix-text" dangerouslySetInnerHTML={{ __html: item.prefix_text.html }} />
        <CountValueComponent number={item.number_field} />
        <div
          className="postfix-text"
          dangerouslySetInnerHTML={{ __html: item.postfix_text.html }}
        />
      </div>
      <div
        className="data-description"
        dangerouslySetInnerHTML={{ __html: item.description_text.html }}
      />
    </li>
  )
}

const NumberFact = ({ data }) => {
  const { primary: section, items } = data
  return (
    <section id={section.section_id} className="uk-section uk-container uk-text-center">
      <div
        className="uk-container uk-container-small uk-text-center"
        dangerouslySetInnerHTML={{ __html: section.section_title.html }}
      />
      <div
        className="uk-container uk-container-small uk-margin-bottom"
        dangerouslySetInnerHTML={{ __html: section.section_description.html }}
      />
      <div className="uk-slider uk-visible-toggle" data-uk-slider={true}>
        <div className="uk-position-relative">
          <div
            className="facts uk-container-small uk-margin-auto uk-slider-container"
            tabIndex="-1"
          >
            <ul
              className="uk-slider-items uk-margin-small-top uk-margin-small-bottom uk-child-width-1-3@m k-grid-margin-medium uk-grid-match uk-grid data-wrapper"
              data-uk-grid={true}
            >
              {items.map((item, i) => {
                return <FactItem {...item} key={i} />
              })}
            </ul>
            <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin">
              <li></li>
            </ul>
            <a
              className="uk-position-center-left uk-position-small uk-hidden-hover"
              href="#"
              data-uk-slidenav-previous
              data-uk-slider-item="previous"
            ></a>
            <a
              className="uk-position-center-right uk-position-small uk-hidden-hover"
              href="#"
              data-uk-slidenav-next
              data-uk-slider-item="next"
            ></a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NumberFact
