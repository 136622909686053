import React from "react"
import { Image } from "../VindiciaComponents"

const RelatedDocument = data => {
  return (
    <div className="related-post uk-width-1-2@s uk-width-1-3@m uk-margin-medium-bottom">
      <a className="uk-flex uk-flex-row" href={data.link_item.url}>
        <Image
          src={data.item_icon.fluid.src}
          srcSet={data.item_icon.fluid.srcSet}
          alt={data.item_icon.alt}
          style={{
            height: "auto",
            width: "100px",
            objectFit: "contain",
          }}
        />
        <div
          className="uk-text-left uk-width-1-1"
          dangerouslySetInnerHTML={{ __html: data.item_doc_title.html }}
        />
      </a>
    </div>
  )
}

const RelatedMaterial = ({ data }) => {
  const { primary: section, items } = data
  return (
    <section id={section.section_id} className="uk-section uk-container ">
      <div className="materials-wrapper uk-text-left uk-flex uk-flex-wrap uk-flex-between">
        <div
          className="uk-text-left uk-width-1-1"
          dangerouslySetInnerHTML={{ __html: section.section_title.html }}
        />
        {items.map((item, i) => {
          return <RelatedDocument {...item} key={i} />
        })}
      </div>
    </section>
  )
}

export default RelatedMaterial
