import React from "react"

const ThreeColumnCards = ({ data, children }) => {
  const section = data.primary

  const count = React.Children.toArray(children).length
  const isSlider = count > 3 ? true : undefined

  const styles = {
    backgroundColor: section.background_color || "#fff",
  }

  const cardStyles = {
    backgroundColor: section.card_background_color || "#fff",
  }

  return (
    <section
      id={section.section_id}
      className={`uk-section ${section.muted_background && "uk-background-muted"}`}
      style={{ ...styles }}
    >
      <div className="uk-container">
        {section.section_title_centered ? (
          <h2 className="uk-text-center">{section.section_title_centered.text}</h2>
        ) : null}
        <div
          className={`uk-visible-toggle uk-dark ${data.slice_label === "centered" &&
            "uk-text-center"}`}
          data-uk-slider={isSlider}
          tabindex="-1"
        >
          <div className="uk-position-relative uk-margin-small-bottom">
            <div className="uk-slider-container">
              <ul
                className="uk-slider-items uk-margin-small-top uk-margin-small-bottom uk-child-width-1-3@m k-grid-margin-medium uk-grid-match"
                data-uk-grid
                data-uk-height-match="target: div > .uk-card > .uk-card-header"
              >
                {children}
              </ul>
            </div>
            {isSlider && (
              <a
                className="uk-position-center-left-out violet uk-position-small uk-margin-none uk-border-circle"
                href="#"
                data-uk-slidenav-previous
                data-uk-slider-item="previous"
                style={{ ...cardStyles }}
              />
            )}
            {isSlider && (
              <a
                className="uk-position-center-right-out violet uk-position-small uk-margin-none uk-border-circle"
                href="#"
                data-uk-slidenav-next
                data-uk-slider-item="next"
                style={{ ...cardStyles }}
              />
            )}
          </div>
          <ul className="uk-slider-nav uk-dotnav uk-flex-center"></ul>
        </div>
      </div>
    </section>
  )
}

export default ThreeColumnCards
