import React from "react"
import { Image } from "../VindiciaComponents"

const TwoColumnAccordion = ({ data }) => {
  const section = data.primary

  const collapsible = label => {
    const collapse = {
      collapseStyle: label.includes("collapse")
        ? `uk-position-z-index uk-flex uk-flex-middle uk-padding-small background-pink uk-light`
        : null,
      position: label.includes("collapse") ? { marginLeft: "-200px" } : null,
    }
    return collapse
  }

  return (
    <section
      id={section.section_id}
      className={`two-column-with-accordion ${section.muted_background &&
        "uk-background-muted"} uk-section`}
    >
      <div className="uk-container">
        <div className="uk-grid-large" data-uk-grid>
          <div className="uk-text-center uk-width-2-5@m">
            <Image
              className="uk-position-relative"
              style={data.slice_label && collapsible(data.slice_label).position}
              src={section.image.fixed.src}
              srcSet={section.image.fixed.srcSet}
              width="400"
              alt="Vindicia Retain"
            />
          </div>
          <div
            className={`uk-width-expand uk-flex-middle ${data.slice_label &&
              data.slice_label.includes("image-right") &&
              "uk-flex-first@m"} ${data.slice_label &&
              collapsible(data.slice_label).collapseStyle}`}
          >
            {section.copy && <div dangerouslySetInnerHTML={{ __html: section.copy.html }} />}
            <ul uk-accordion="collapsible: false">
              {data.items.map((item, i) => (
                <li key={`item-${i}`}>
                  <a href="#" className="uk-accordion-title">
                    <strong>
                      <span uk-icon="chevron-right"></span> {item.accordion_element_title}
                    </strong>
                  </a>
                  <div
                    className="uk-accordion-content"
                    dangerouslySetInnerHTML={{ __html: item.accordion_element_body.html }}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TwoColumnAccordion
