import React from "react"
import { Image, Background } from "../VindiciaComponents"

const SliderWithBackground = ({ data }) => {
  return (
    <section id={data.primary.section_id} className="product-slider">
      <Background src={data.primary.background_image.fluid.src}>
        <div
          className="uk-container uk-position-relative uk-visible-toggle uk-light"
          tabIndex="-1"
          data-uk-slider="autoplay: true"
        >
          <ul className="uk-slider-items uk-child-width-1-1" data-uk-grid>
            {data.items.map((slide, i) => (
              <li key={`slide-${i}`}>
                <h2 className="uk-margin-large-top">{slide.slide_title}</h2>
                <Image
                  src={slide.slide_image_desktop.fluid.src}
                  srcSet={slide.slide_image_desktop.fluid.srcSet}
                  style={{
                    objectFit: "contain",
                  }}
                  className="slide-image hide-on-mobile"
                />
                <Image
                  src={slide.slide_image_mobile.fixed.src}
                  srcSet={slide.slide_image_mobile.fixed.srcSet}
                  style={{
                    objectFit: "contain",
                  }}
                  className="slide-image hide-on-desktop"
                />
                <div dangerouslySetInnerHTML={{ __html: slide.slide_copy.html }} />
              </li>
            ))}
          </ul>
          <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin">
            <li></li>
          </ul>
          <a
            className="uk-position-center-left uk-position-small uk-hidden-hover"
            href="#"
            data-uk-slidenav-previous
            data-uk-slider-item="previous"
          ></a>
          <a
            className="uk-position-center-right uk-position-small uk-hidden-hover"
            href="#"
            data-uk-slidenav-next
            data-uk-slider-item="next"
          ></a>
        </div>
      </Background>
    </section>
  )
}

export default SliderWithBackground
