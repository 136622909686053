import React from "react"
import { Image } from "../VindiciaComponents"

const LogoCarousel = ({ data }) => {
  const section = data.primary
  const items = data.items

  return (
    <section id={section.section_id || ""} className="uk-section uk-section-small">
      <div className="uk-container uk-container-expand">
        {section.title && (
          <div
            className="uk-text-center"
            dangerouslySetInnerHTML={{ __html: section.title.html }}
          />
        )}

        {section.description && (
          <div
            className="uk-text-center"
            dangerouslySetInnerHTML={{ __html: section.description.html }}
          />
        )}
        <div
          className="uk-slider-container uk-flex-center"
          data-uk-slider="autoplay: true; sets: false; autoplay-interval: 2000; clsActivated: uk-transition-active"
        >
          <div
            className="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-5@m uk-margin-medium-bottom"
            data-uk-grid
          >
            {items.map((image, i) => (
              <div key={`client-${i}`} className="uk-text-center">
                <Image
                  src={image.logo.fixed.src}
                  srcSet={image.logo.fixed.srcSet}
                  width={image.logo.fixed.width}
                  height={image.logo.fixed.height}
                  className="client-logo"
                  alt={image.logo.alt}
                />
              </div>
            ))}
          </div>
          {section.button_url && (
            <div className="uk-flex uk-flex-center uk-margin-top">
              <a
                className="uk-button uk-button-primary uk-border-pill"
                href={section.button_url.url}
                target={section.button_url.target}
              >
                {section.button_text}
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default LogoCarousel
