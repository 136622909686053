import React from "react"
import { Image } from "../VindiciaComponents"

const FourColumnWithIcon = ({ data }) => {
  const section = data.primary
  const items = data.items
  const theme = section.section_theme || "uk-dark"

  return (
    <section
      id={section.section_id}
      className={`four-columns-with-icon uk-section ${theme}`}
      style={{ backgroundColor: section.section_background_color || "#fff" }}
    >
      <div className="uk-container">
        <div
          className={`${
            section.section_description ? "uk-margin-bottom" : "uk-margin-medium-bottom"
          } uk-text-center`}
          dangerouslySetInnerHTML={{ __html: section.section_heading.html }}
        />
        {section.section_description.html && (
          <div
            className="uk-margin-medium-bottom uk-text-center"
            dangerouslySetInnerHTML={{ __html: section.section_description.html }}
          />
        )}
        <div className="uk-grid uk-child-width-1-4@m uk-flex-center" data-uk-grid>
          {items.map((item, i) => (
            <div key={`item-${i}`}>
              <div className="uk-margin uk-flex uk-flex-column uk-flex-left">
                <div
                  style={{ maxHeight: "50px", maxWidth: "50px" }}
                  className={`uk-flex uk-flex-middle uk-flex-center uk-border-circle uk-margin-small-bottom`}
                >
                  <Image src={item.icon.fluid.src} srcSet={item.icon.fluid.srcSet} alt="Vindicia" />
                </div>
                {item.title && (
                  <h3 className="uk-margin-remove-top uk-margin-small-bottom">
                    <b className="">{item.title}</b>
                  </h3>
                )}
                {item.description && (
                  <div dangerouslySetInnerHTML={{ __html: item.description.html }} />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default FourColumnWithIcon
