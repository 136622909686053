import React from "react"
import { Image } from "../VindiciaComponents"

const ImageItem = ({ item_image, children }) => {
  return (
    <div
      className="image-gallery-container uk-margin-medium-bottom uk-transition-toggle"
      tabindex="0"
    >
      <div className="uk-position-relative uk-flex uk-flex-middle uk-flex-center uk-height-1-1">
        <Image {...item_image.fluid} />
        {children}
      </div>
    </div>
  )
}

const Images = ({ items }) => {
  return (
    <div
      uk-grid
      className="uk-grid uk-grid-match uk-child-width-1-4@l uk-child-width-1-3@m uk-flex-center"
    >
      {items.map(({ item_description, ...item }) => {
        return (
          <ImageItem {...item} key={item.item_title}>
            <div className="image-gallery-overlay uk-overlay-primary uk-position-cover uk-height-1-1 uk-flex uk-flex-middle uk-flex-center uk-flex-column uk-transition-fade uk-text-center">
              <div dangerouslySetInnerHTML={{ __html: item_description.html }} />
            </div>
          </ImageItem>
        )
      })}
    </div>
  )
}

const ImageGallery = ({ data }) => {
  const { primary: section, items } = data
  const { item_description, ...item } = items[0]
  return (
    <section id={section.section_id} className={`uk-container uk-section`}>
      <div className="uk-text-center uk-container-small uk-margin-auto uk-margin-medium-bottom">
        {section.section_name && <h2 className="uk-text-center">{section.section_name}</h2>}
        {section.section_description && (
          <div dangerouslySetInnerHTML={{ __html: section.section_description.html }} />
        )}
      </div>
      <Images items={items} />
    </section>
  )
}

export default ImageGallery
