import React from "react"
import { Link } from "gatsby"
import { Image } from "../VindiciaComponents"

const ThreeColumnGrid = ({ data }) => {
  const section = data.primary
  const items = data.items

  return (
    <section
      id={section.section_id}
      className={`uk-section ${section.muted_background &&
        "uk-background-muted"} three-column-grid`}
    >
      <div className="uk-container">
        <h2 className="uk-margin-bottom">{section.section_title.text}</h2>
        <div
          className={`uk-grid uk-child-width-1-3@m ${data.slice_label === "centered" &&
            "uk-text-center"}`}
          data-uk-grid
        >
          {items &&
            items.map((item, i) => (
              <div key={`item-${i}`}>
                <div className="uk-flex uk-flex-left">
                  <Image
                    src={item.sub_mgmt_items.document.data.icon_image.fixed.src}
                    srcSet={item.sub_mgmt_items.document.data.icon_image.fixed.srcSet}
                    alt="Vindicia"
                  />
                </div>
                <div>
                  <h4 className="uk-margin-remove">
                    <Link
                      to={`${
                        item.sub_mgmt_items.document.data.url_prefix != null
                          ? `/${item.sub_mgmt_items.document.data.url_prefix}`
                          : ""
                      }${
                        item.sub_mgmt_items.document.data.second_url_prefix != null
                          ? `/${item.sub_mgmt_items.document.data.second_url_prefix}`
                          : ""
                      }/${item.sub_mgmt_items.uid}`}
                    >
                      {item.sub_mgmt_items.document.data.short_title}
                      <span uk-icon="icon: arrow-right" />
                    </Link>
                  </h4>
                  {item.sub_mgmt_items.document.data.short_copy && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.sub_mgmt_items.document.data.short_copy.html,
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default ThreeColumnGrid
