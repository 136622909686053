import React from "react"
import { Image } from "../VindiciaComponents"

const ThreeColumnWithIcon = ({ data }) => {
  const section = data.primary
  const items = data.items

  return (
    <section
      id={section.section_id}
      className={`three-columns-with-icon ${section.muted_background &&
        "uk-background-muted"} uk-section`}
    >
      <div className="uk-container">
        <h2 className="uk-margin-bottom uk-text-center">{section.section_name}</h2>
        <div
          className={`uk-grid uk-child-width-1-3@m ${data.slice_label === "centered" &&
            "uk-text-center"}`}
          data-uk-grid
        >
          {items.map((item, i) => (
            <div key={`item-${i}`}>
              <div className="uk-margin uk-flex uk-flex-center">
                <div
                  style={{ minHeight: "100px", minWidth: "100px" }}
                  className={`uk-flex uk-flex-middle uk-flex-center uk-border-circle`}
                >
                  <Image
                    src={item.icon_image.fixed.src}
                    srcSet={item.icon_image.fixed.srcSet}
                    alt="Vindicia"
                  />
                </div>
              </div>
              <div>
                {item.short_copy && (
                  <div dangerouslySetInnerHTML={{ __html: item.short_copy.html }} />
                )}
                {item.cta_link_url && (
                  <a href={item.cta_link_url.url}>
                    View plan<span uk-icon="icon: arrow-right"></span>
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ThreeColumnWithIcon
