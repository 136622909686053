import React from "react"
import { Background } from "../VindiciaComponents"

const FullWidthBanner = ({ data }) => {
  const section = data.primary
  return (
    <section id={section.section_id} className={`uk-section`}>
      <div className="fullwidthbanner-wrapper">
        <Background
          src={section.background_image.fluid.src}
          className="fullwidthbanner-background"
        />
        <div
          className={`fullwidthbanner ${
            section.theme_color_section ? section.theme_color_section : "full-section-colored"
          }`}
        >
          <div className="uk-container uk-margin-xlarge-top uk-margin-xlarge-bottom">
            <div className="uk-grid-large uk-light" data-uk-grid>
              <div className={`uk-width-expand uk-flex uk-flex-middle uk-flex-first@m`} />
              <div className={`uk-width-3-5@m contents`}>
                {section.copy && <div dangerouslySetInnerHTML={{ __html: section.copy.html }} />}
                {section.cta_url && section.cta_text && (
                  <a
                    className="uk-button uk-border-pill"
                    href={section.cta_url.url}
                    target={section.cta_url.target}
                  >
                    {section.cta_text}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FullWidthBanner
