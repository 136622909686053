import React from "react"

const OneColumn = ({ data }) => {
  const section = data.primary
  return (
    <section
      id={section.section_id}
      className={`uk-section ${section.muted_background && "uk-background-muted"}`}
    >
      <div className="uk-container uk-container-small">
        {section.copy && (
          <div
            className={`${data.slice_label} ${
              data.slice_label && data.slice_label.includes("center") ? "uk-text-center" : null
            }`}
            dangerouslySetInnerHTML={{ __html: section.copy.html }}
          />
        )}
      </div>
    </section>
  )
}

export default OneColumn
