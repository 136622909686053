import React from "react"
import ReactPlayer from "react-player"

const TwoColumnWithVideo = ({ data }) => {
  const section = data.primary

  const theme = section.section_theme || "uk-light"

  const collapsible = label => {
    const collapse = {
      collapseStyle: label.includes("collapse")
        ? `uk-position-z-index uk-flex uk-flex-middle uk-padding-small background-pink uk-light`
        : null,
      position: label.includes("collapse") ? { marginLeft: "-200px" } : null,
    }
    return collapse
  }

  return (
    <section
      id={section.section_id}
      className={`uk-section two-column-with-video-section ${section.muted_background &&
        "uk-background-muted"} ${theme}`}
      style={{ backgroundColor: section.section_background_color || "#fff" }}
    >
      <div className={`uk-container two-column-with-image`}>
        <div className="uk-grid-large" data-uk-grid>
          <div className="uk-text-center uk-width-2-5@m">
            {section.vimeo_id && (
              <div className="uk-width-1-1" style={{ height: "360px" }}>
                <iframe
                  // src="https://player.vimeo.com/video/252443587?autoplay=1&muted=1&dnt=1&controls=1"
                  src={`https://player.vimeo.com/video/${section.vimeo_id}?autoplay=1&muted=1&dnt=1&controls=1`}
                  className="optanon-category-C0004 uk-width-1-1 uk-height-1-1"
                ></iframe>
              </div>

              // <ReactPlayer
              //   url={`https://vimeo.com/` + section.vimeo_id}
              //   controls={true}
              //   width={"100%"}
              // />
            )}
          </div>
          <div
            className={`uk-width-expand uk-flex uk-flex-middle ${data.slice_label &&
              data.slice_label.includes("image-right") &&
              "uk-flex-first@m"} ${data.slice_label &&
              collapsible(data.slice_label).collapseStyle}`}
          >
            <div>
              {section.copy && <div dangerouslySetInnerHTML={{ __html: section.copy.html }} />}
              {section.cta_url && section.cta_text && (
                <a
                  className="uk-button uk-border-pill"
                  href={section.cta_url.url}
                  target={section.cta_url.target}
                >
                  {section.cta_text}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TwoColumnWithVideo
