import React from "react"
import { Image } from "../VindiciaComponents"

const ThreeColumnIconCard = ({ data }) => {
  const section = data.primary
  const items = data.items
  const theme = section.section_theme || "uk-dark"

  const cardStyle = {
    padding: "20px",
    backgroundColor: section.cards_background_color || "#f4f3f1",
    borderRadius: "20px",
  }

  return (
    <section
      id={section.section_id}
      className={`three-columns-with-icon uk-section ${theme}`}
      style={{ backgroundColor: section.section_background_color || "#fff" }}
    >
      <div className="uk-container">
        <div
          className={`${
            section.section_description ? "uk-margin-bottom" : "uk-margin-medium-bottom"
          } uk-text-center`}
          dangerouslySetInnerHTML={{ __html: section.section_heading.html }}
        />
        {section.section_description.html && (
          <div className="uk-flex uk-flex-center">
            <div
              className="uk-margin-medium-bottom uk-text-center uk-width-1-2@m"
              dangerouslySetInnerHTML={{ __html: section.section_description.html }}
            />
          </div>
        )}
        <div className="uk-grid uk-child-width-1-3@m uk-grid-match uk-flex-center" data-uk-grid>
          {items.map((item, i) => (
            <div key={`item-${i}`}>
              <div className="uk-margin uk-flex uk-flex-column uk-flex-left" style={cardStyle}>
                <div
                  style={{ maxHeight: "50px", maxWidth: "50px" }}
                  className="uk-flex uk-flex-middle uk-flex-center uk-border-circle uk-margin-medium-bottom"
                >
                  <Image src={item.icon.fluid.src} srcSet={item.icon.fluid.srcSet} alt="Vindicia" />
                </div>
                {item.title && (
                  <h3 className="uk-margin-remove-top uk-margin-small-bottom">
                    <b>{item.title}</b>
                  </h3>
                )}
                {item.description && (
                  <div dangerouslySetInnerHTML={{ __html: item.description.html }} />
                )}
                {item.link_url.url && (
                  <a
                    className="uk-margin-auto-top"
                    href={item.link_url.url}
                    target={item.link_url.target}
                  >
                    <b>{item.link_title}</b>
                    <span uk-icon="icon: chevron-right; ratio: 0.8"></span>
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ThreeColumnIconCard
