import React, { useState } from "react"
import ReactPlayer from "react-player"

const FullWidthBannerWithVideoOverlay = ({ data }) => {
  const section = data.primary
  const { video_id } = section
  const [isActive, setActive] = useState(false)
  return (
    <section
      id={section.section_id}
      className={`uk-section  ${section.muted_background &&
        "uk-background-muted"} fullwidthbanner fullwidthbanner-video uk-flex uk-flex-middle`}
      style={{
        position: `relative`,
        background: `url(${section.background_image.fluid.src})`,
        backgroundPosition: `center`,
        backgroundSize: `cover`,
        backgroundRepeat: `no-repeat`,
      }}
    >
      <div
        className="videowrapper"
        style={{ opacity: isActive ? 1 : 0, pointerEvents: isActive ? "all" : "none" }}
      >
        <ReactPlayer url={`https://vimeo.com/${video_id}`} controls={true} height="360px" />
        <button
          className="uk-button uk-border-pill"
          style={{ display: `inline-block`, marginTop: `15px` }}
          onClick={() => setActive(!isActive)}
        >
          Close Video
        </button>
      </div>

      <div className="uk-container">
        <div className="uk-grid-large uk-light" data-uk-grid>
          <div className={`uk-width-expand uk-flex uk-flex-middle uk-flex-first@m`} />
          <div className={`uk-width-3-5@m contents`}>
            {section.copy && <div dangerouslySetInnerHTML={{ __html: section.copy.html }} />}
            <button className="uk-button uk-border-pill" onClick={() => setActive(!isActive)}>
              Watch Video
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FullWidthBannerWithVideoOverlay
