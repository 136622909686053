import React from "react"
import { Image } from "../VindiciaComponents"

const PluralItem = ({ item_title, item_image, button_link, button_label, children }) => {
  return (
    <div className="uk-margin-medium-bottom">
      <Image {...item_image.fluid} />
      <h3>{item_title}</h3>
      {children}
      <a
        className="uk-button uk-button-primary uk-border-pill"
        href={button_link.url}
        target={button_link.target}
      >
        {button_label}
      </a>
    </div>
  )
}

const SingularItem = ({ item_title, item_image, button_link, button_label, children }) => {
  return (
    <div className="uk-grid uk-child-width-1-2@m">
      <div>
        <Image {...item_image.fluid} />
      </div>
      <div>
        <h3>{item_title}</h3>
        {children}
        <a
          className="uk-button uk-button-primary uk-border-pill"
          href={button_link.url}
          target={button_link.target}
        >
          {button_label}
        </a>
      </div>
    </div>
  )
}

const MultipleItems = ({ items }) => {
  return (
    <div className="uk-grid uk-child-width-1-3@m uk-flex-center">
      {items.map(({ item_description, ...item }) => {
        return (
          <PluralItem {...item} key={item.item_title}>
            <div dangerouslySetInnerHTML={{ __html: item_description.html }} />
          </PluralItem>
        )
      })}
    </div>
  )
}

const PromotionalComponent = ({ data }) => {
  const { primary: section, items } = data
  const { item_description, ...item } = items[0]

  return (
    <section id={section.section_id} className={`uk-container uk-section`}>
      <div className="uk-text-center uk-container-small uk-margin-auto uk-margin-medium-bottom">
        {section.section_promo_title && (
          <h2 className="uk-text-center">{section.section_promo_title}</h2>
        )}
        {section.section_description && (
          <div dangerouslySetInnerHTML={{ __html: section.section_description.html }} />
        )}
      </div>
      {items.length > 1 ? (
        <MultipleItems items={items} />
      ) : (
        <SingularItem {...item}>
          <div dangerouslySetInnerHTML={{ __html: item_description.html }} />
        </SingularItem>
      )}
    </section>
  )
}

export default PromotionalComponent
