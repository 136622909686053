import React from "react"
import { Background } from "../VindiciaComponents"

const BackgroundItem = data => {
  return (
    <a
      className="uk-flex uk-overflow-hidden uk-flex-center hover-background-item"
      href={data.button_link.url}
    >
      <Background
        src={data.background.fluid.src}
        srcSet={data.background.fluid.srcSet}
        className="hover-background"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <div
        className="hover-text uk-flex uk-flex-center uk-flex-middle uk-flex-column"
        onMouseOver={ev =>
          (ev.target.closest(".hover-text").style.background = `${data.overlay_color}80`)
        }
        onMouseOut={ev => (ev.target.style.background = "")}
      >
        <div className="uk-text-center" dangerouslySetInnerHTML={{ __html: data.heading.html }} />
        <div
          className="uk-text-center "
          dangerouslySetInnerHTML={{ __html: data.description.html }}
        />
        <button className="uk-button uk-border-pill" type="button">
          {data.button_link_text}
        </button>
      </div>
    </a>
  )
}

const HoverBackgroundZoom = ({ data }) => {
  const { primary: section, items } = data
  return (
    <section
      id={section ? section.section_id : ""}
      className={`uk-section hover-background-section uk-flex uk-flex-row`}
    >
      {items.map((item, i) => {
        return <BackgroundItem {...item} key={i} />
      })}
    </section>
  )
}

export default HoverBackgroundZoom
