import React from "react"
import { Image } from "../VindiciaComponents"

const Testimonials = ({ data }) => {
  const theme = data.testimonial_section_theme || "uk-light"

  return (
    <section
      className={`uk-cover-container homepage-testimonials ${theme}`}
      style={{ backgroundColor: data.section_background_color || "#fff" }}
    >
      <div className="uk-container uk-flex uk-flex-center uk-margin-medium-top uk-margin-medium-bottom">
        <div className="uk-flex uk-flex-center uk-position-z-index" data-uk-grid>
          <div className="uk-width-3-5@m">
            <div
              className="uk-position-relative uk-margin-large-top"
              data-uk-slider="autoplay: true; easing: cubic-bezier(.16,.75,.47,1)"
            >
              <ul className="uk-slider-items uk-child-width-1-1">
                {data.testimonial_quote.map((quote, i) => (
                  <li key={`quote-${i}`}>
                    <div className="quote-wrapper">
                      <h4 className="quote">{quote.copy}</h4>
                      <h5>{quote.source}</h5>
                      <Image
                        className="logo"
                        src={quote.logo.fixed.src}
                        srcSet={quote.logo.fixed.srcSet}
                        alt={quote.logo.alt}
                      />
                      <br />
                      {quote.case_study && (
                        <p>
                          <a href={quote.case_study}>
                            Read success story<span uk-icon="icon: arrow-right"></span>
                          </a>
                        </p>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
              <ul className="uk-slider-nav uk-dotnav">
                <li></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonials
