import React from "react"

const OneColumnScroller = section => {
  const theme = section.data.primary.section_theme || "uk-light"

  return (
    <section
      className={`uk-section one-column-scroller ${theme}`}
      style={{ backgroundColor: section.data.primary.section_background_color || "#fff" }}
    >
      <div className="uk-container uk-container-small uk-text-center">
        {section.title && (
          <div
            className="uk-text-center"
            dangerouslySetInnerHTML={{ __html: section.title.html }}
          />
        )}
        {section.description && (
          <div
            className="uk-text-center"
            dangerouslySetInnerHTML={{ __html: section.description.html }}
          />
        )}
        <div
          className="uk-position-relative"
          data-uk-slider="autoplay: true; easing: cubic-bezier(.16,.75,.47,1)"
        >
          <ul className={`${section.data.slice_label} uk-slider-items uk-child-width-1-1`}>
            {section.data.items.map((quote, i) => (
              <li key={`quote-${i}`}>
                <div className="uk-margin-bottom">
                  <div
                    dangerouslySetInnerHTML={{ __html: quote.copy?.html || quote.copy_text?.html }}
                  />
                  {quote.author && (
                    <div
                      className="author"
                      dangerouslySetInnerHTML={{ __html: quote.author.html }}
                    />
                  )}
                  {quote.job_title && (
                    <div
                      className="author"
                      dangerouslySetInnerHTML={{ __html: quote.job_title.html }}
                    />
                  )}
                  <br />
                  {quote.cta_text && quote.cta_url && (
                    <a
                      className="uk-button uk-button-large uk-border-pill uk-button-primary"
                      href={quote.cta_url.url}
                      target={quote.cta_url.target}
                    >
                      {quote.cta_text}
                    </a>
                  )}
                </div>
              </li>
            ))}
          </ul>
          <ul className="uk-slider-nav uk-dotnav uk-flex-center">
            <li></li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default OneColumnScroller
